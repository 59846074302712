import { apiClient } from "./apiClient";

export const validatePayment = (payload: {
  orderId: string;
  email?: string;
  subscriptionMonths: number;
}) =>
  apiClient({
    path: "/validate-payment",
    method: "POST",
    body: payload,
  });

export const createPayment = (payload: { orderId: string; email?: string }) =>
  apiClient({
    path: "/create-payment",
    method: "POST",
    body: payload,
  });
