import axios, { Method, ResponseType } from "axios";
import environment from "../config/environment";

type RequestOptions = {
  method?: Method;
  path: string;
  body?: Record<string, unknown>;
  query?: string;
  params?: Record<string, string>;
  headers?: Record<string, string>;
  responseType?: ResponseType;
  withCredentials?: boolean;
};

export const apiClient = async (options: RequestOptions) => {
  const {
    method = "GET",
    path,
    body,
    headers = {},
    query = {},
    params,
    responseType,
    withCredentials = true,
  } = options;

  const token = localStorage.getItem("token");

  const headersWithAuth = token
    ? {
        ...headers,
        authorization: `Bearer ${token}`,
      }
    : headers;

  try {
    const response = await axios.request({
      method,
      url: getUrl(path, params),
      data: body,
      headers: headersWithAuth,
      params: query,
      responseType,
      withCredentials,
    });
    const formattedResponse = {
      data: response.data,
      success: true,
      status: response.status,
    };

    return formattedResponse;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.data) {
      return {
        data: error.response?.data,
        status: error.response?.status,
        success: false,
      };
    }
    throw error;
  }
};

const getUrl = (path: string, params?: Record<string, string>) => {
  let url = `${environment.serverUrl}${process.env.NODE_ENV === "development" ? `:${environment.serverPort}` : ""}${path}`;

  if (!params) {
    return url;
  }

  Object.keys(params).forEach((key) => {
    const value = params[key];
    if (value === undefined) {
      return;
    }
    url = url.replace(`:${key}`, value);
  });

  return url;
};
