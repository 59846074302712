type AppEnv = {
  serverUrl: string;
  clientUrl: string;
  serverPort?: number;
  env: string;
  paypalClientId: string;
};

const production: AppEnv = {
  serverUrl: "https://data-noobs-server-e3930493fa5c.herokuapp.com",
  clientUrl: "https://data-noobs-client-10005e625cee.herokuapp.com",
  env: "prod",
  paypalClientId:
    "AegftIM53tn6gmNiW_nNSiYM_4S3lF4XuYq9OhR0H0Pj5bDgKReb3pTKJTso-ma0XxbxcJdOymSynlV-",
};

const development: AppEnv = {
  env: "local",
  serverUrl: "http://localhost",
  clientUrl: "http://localhost",
  serverPort: 3300,
  paypalClientId:
    "ATtLsCN4B6G3lTPD8MzzV3yJNIDM1qUDVzFfjJ8BtE-lxwfyLo19XwOqKfmlB3Fq7PIUF75RPEQ1TN36",
};

const reactEnvironment = process.env.NODE_ENV as string;

let configurations = production;

if (reactEnvironment === "development") {
  configurations = development;
}

export default configurations;
