export interface NodeSqlParserError {
  message: string;
  found: string;
  location: {
    start: { offset: number; line: number; column: number };
    end: { offset: number; line: number; column: number };
  };
}

export const parseSqlParserError = (error: NodeSqlParserError): string => {
  const {
    found,
    location: {
      start: { line, column },
    },
  } = error;

  const parsedFound = found === "\n" ? "\\n" : found;
  return `Syntax error at line ${line}, character ${column}: invalid key "${parsedFound}"`;
};
