import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { useEffect, useState } from "react";
import { createPayment, validatePayment } from "../../api/paymentApi";
import { useUserContext } from "../userContext";
import { SyncLoader } from "react-spinners";
import Button from "../../uiKit/button";
import { useNavigate, useParams } from "react-router-dom";
import { PAYMENT_MONTHS } from "../../App";
import { PayPalButtonsWrapper } from "./payPalButton";
import environment from "../../config/environment";
import { useMixpanel } from "react-mixpanel-browser";
import { toast } from "react-toastify";

const DISCOUNT_CODE = "datanoobs-nov24-sale";
const DISCOUNT_RATE = 0.5;

export const paymentMonthsPrices: Record<string, number> = {
  "1": 30.0,
  "3": 60.0,
  "6": 100.0,
};

const getPriceFromSession = (paymentMonths: string) => {
  const isDiscountApplied = localStorage.getItem("discountApplied");
  if (isDiscountApplied) {
    return paymentMonthsPrices[paymentMonths] * DISCOUNT_RATE;
  }
  return paymentMonthsPrices[paymentMonths];
};

const PaymentPage = () => {
  const params = useParams();
  const paymentMonths = params[PAYMENT_MONTHS] as string;

  const [error, setError] = useState<string | null>(null);
  const [promoCodeError, setPromoCodeError] = useState<string | null>(null);
  const [paidFor, setPaidFor] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [promoCode, setPromoCode] = useState("");
  const [isDiscountApplied, setIsDiscountApplied] = useState<
    boolean | null | undefined
  >(!!localStorage.getItem("discountApplied"));

  const navigate = useNavigate();
  const { user } = useUserContext();
  const mixpanel = useMixpanel();

  useEffect(() => {
    if (user?.premiumExpiry) {
      setPaidFor(true);
    }
  }, [user]);

  const handlePromoCodeApply = () => {
    mixpanel?.track("payment_promo_code_apply", {
      email: user?.email,
      name: user?.fullName,
      months: Number(paymentMonths),
      promoCode,
      DISCOUNT_CODE,
    });
    if (promoCode === DISCOUNT_CODE) {
      toast("Promo code discount applied!", { type: "success" });
      setIsDiscountApplied(true);
      localStorage.setItem("discountApplied", "true");
      setPromoCodeError(null);
    } else {
      setPromoCodeError("Invalid promo code");
    }
  };

  const createOrder = async (
    data: any,
    actions: { order: { create: (arg: any) => any } }
  ) => {
    try {
      mixpanel?.track("payment_create_order", {
        email: user?.email,
        name: user?.fullName,
        months: paymentMonths,
        isDiscountApplied,
      });

      if (!user) {
        mixpanel?.track("payment_error", {
          months: Number(paymentMonths),
          isDiscountApplied,
          error: "User not found while creating order",
        });
        setError("User not found. Log in first");
        return Promise.resolve("error");
      }

      setIsLoading(true);
      const order = await actions.order.create({
        intent: "CAPTURE",
        purchase_units: [
          {
            amount: {
              currency_code: "USD",
              value: getPriceFromSession(paymentMonths),
            },
          },
        ],
      });
      await createPayment({ email: user.email, orderId: order });
      setIsLoading(false);
      return order;
    } catch (err) {
      mixpanel?.track("payment_error", {
        email: user?.email,
        name: user?.fullName,
        months: Number(paymentMonths),
        isDiscountApplied,
        error: err,
      });
      setError(
        "Payment creation failed. Please try again or contact support at admin@data-noobs.com"
      );
    }
  };

  const handleApprove = async (orderId: string) => {
    try {
      mixpanel?.track("payment_approve_order", {
        email: user?.email,
        name: user?.fullName,
        months: Number(paymentMonths),
        isDiscountApplied,
        orderId,
      });

      if (!user) {
        mixpanel?.track("payment_error", {
          months: Number(paymentMonths),
          orderId,
          isDiscountApplied,
          error: "User not found while handling approve",
        });
        setError("User not found. Log in first");
        return;
      }

      const response = await validatePayment({
        orderId,
        email: user?.email,
        subscriptionMonths: Number(paymentMonths),
      });

      if (response.success) {
        mixpanel?.track("payment_success", {
          email: user?.email,
          name: user?.fullName,
          months: Number(paymentMonths),
          isDiscountApplied,
          orderId,
        });
        setPaidFor(true);
        localStorage.removeItem("discountApplied");
      } else {
        mixpanel?.track("payment_error", {
          email: user?.email,
          name: user?.fullName,
          months: Number(paymentMonths),
          isDiscountApplied,
          orderId,
          error: response.data,
        });
        setError(
          "Failed to create PayPal payment. Please try again or contact support at admin@data-noobs.com"
        );
      }
    } catch (error) {
      mixpanel?.track("payment_error", {
        email: user?.email,
        name: user?.fullName,
        months: Number(paymentMonths),
        isDiscountApplied,
        orderId,
        error,
      });
      setError(
        "Payment creation failed. Please try again or contact support at admin@data-noobs.com"
      );
    }
  };

  if (!paymentMonthsPrices[paymentMonths]) {
    return (
      <div className="flex flex-col items-center pt-16 min-h-screen p-2 bg-gradient-to-b from-gray-50 to-gray-100">
        <div className="font-bold text-3xl text-gray-800 p-2 rounded-lg mb-8">
          Something went wrong, please select a premium option
        </div>
        <Button className="" onClick={() => navigate("/premium")}>
          Back premium options
        </Button>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center pt-16 min-h-screen p-4 bg-gradient-to-b from-gray-50 to-gray-100">
      <div className="font-bold text-5xl text-gray-800 p-2 rounded-lg mb-2">
        {paidFor ? "Checkout Completed" : "Confirm Your Purchase"}
      </div>
      {paidFor ? null : (
        <div className="mb-8 font-semibold text-lg text-gray-600 rounded-lg md:px-16">
          <li>{`Get ${paymentMonths} ${paymentMonths === "1" ? "month" : "months"} access to the SQL practice platform`}</li>
          <li>
            Price:{" "}
            {isDiscountApplied || localStorage.getItem("discountApplied") ? (
              <span>
                <span className="line-through">
                  ${paymentMonthsPrices[paymentMonths]}
                </span>{" "}
                <span className="text-green-600 font-bold">
                  ${paymentMonthsPrices[paymentMonths] * DISCOUNT_RATE}
                </span>
              </span>
            ) : (
              <span>${paymentMonthsPrices[paymentMonths]}</span>
            )}
          </li>
          <li>
            <label htmlFor="terms">
              By paying you adhere to the{" "}
              <a
                href="https://data-noobs.com/%d7%aa%d7%a7%d7%a0%d7%95%d7%9f-%d7%a9%d7%99%d7%9e%d7%95%d7%a9-%d7%91%d7%90%d7%aa%d7%a8/"
                target="_blank"
                className="text-dnPurple underline"
                rel="noreferrer"
              >
                Terms and Conditions
              </a>{" "}
              and{" "}
              <a
                href="https://data-noobs.com/%d7%9e%d7%93%d7%99%d7%a0%d7%99%d7%95%d7%aa-%d7%a4%d7%a8%d7%98%d7%99%d7%95%d7%aa/"
                target="_blank"
                className="text-dnPurple underline"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
            </label>
          </li>
          <div className="flex justify-center items-center bg-gray-100">
            <div className="flex flex-col mt-4 bg-white px-4 py-2 rounded-lg shadow-lg w-full max-w-md">
              <div className="flex flex-col md:flex-row items-center w-full gap-3">
                <label className="text-gray-600 text-lg font-medium md:mb-0">
                  Promo Code
                </label>
                <input
                  type="text"
                  value={promoCode}
                  onChange={(e) => setPromoCode(e.target.value)}
                  className="flex-1 border border-gray-300 rounded-lg px-3 py-2 text-gray-800 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  placeholder="Enter code"
                />
                <Button
                  onClick={handlePromoCodeApply}
                  className="py-2 px-6 text-white bg-indigo-600 hover:bg-indigo-700 transition-colors duration-200 rounded-lg"
                >
                  Apply
                </Button>
              </div>
              {promoCodeError && (
                <div className="text-red-500 font-medium mt-2 mb-1 text-center">
                  {promoCodeError}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <PayPalScriptProvider
        options={{
          clientId: environment.paypalClientId,
        }}
      >
        {paidFor ? (
          <div className="flex flex-col items-center mb-4 p-6 bg-white shadow-lg rounded-lg">
            <span className="mb-4 text-2xl text-green-600 font-bold">
              Payment Successful! 🎉
            </span>
            <span className="text-lg text-gray-600 mb-6 font-bold">
              You've unlocked access to all questions. Now it's time to start
              practicing and sharpen your SQL skills!
            </span>
            <Button className="" onClick={() => navigate("/")}>
              Explore all questions
            </Button>
          </div>
        ) : (
          <div className="w-full max-w-md bg-white p-6 shadow-lg rounded-lg">
            {error && (
              <div className="text-red-500 text-lg font-semibold mb-4 bg-red-100 p-3 rounded-lg">
                {error}
              </div>
            )}
            {!error && (
              <div className="flex flex-col py-4">
                <PayPalButtonsWrapper
                  createOrder={createOrder}
                  handleApprove={handleApprove}
                  setError={(error) => {
                    mixpanel?.track("payment_error", {
                      email: user?.email,
                      name: user?.fullName,
                      months: Number(paymentMonths),
                      isDiscountApplied,
                      error,
                    });
                    setError(error);
                  }}
                  setIsLoading={setIsLoading}
                />
              </div>
            )}
            {isLoading && (
              <div className="flex justify-center my-6">
                <SyncLoader
                  color={"#6456FF"}
                  loading={isLoading}
                  cssOverride={{
                    display: "block",
                    margin: "0 auto",
                  }}
                  size={24}
                />
              </div>
            )}
          </div>
        )}
      </PayPalScriptProvider>
    </div>
  );
};

export default PaymentPage;
