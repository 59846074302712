import { apiClient } from "./apiClient";

export const getAiOpinion = async (payload: {
  query: string;
  questionId: string;
  userEmail?: string;
}) => {
  return await apiClient({
    path: "/ai-opinion",
    method: "POST",
    body: payload,
  });
};
