import React, { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import QuestionForm from "./questionForm";
import {
  createQuestion,
  updateQuestion,
  deleteQuestion,
  QUESTIONS_QUERY_KEYS,
  resaveQuestions,
  getQuestionsQueryAdmin,
} from "../api/questionsApi";
import Button from "../uiKit/button";
import { useUserContext } from "../authentication/userContext";
import { SyncLoader } from "react-spinners";
import { toast } from "react-toastify";
import { Question } from "./types";
import { DifficultyLevel } from "../sqlEditor/difficultyLevel";

const QuestionManager: React.FC = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [editingQuestion, setEditingQuestion] = useState<Question | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isLoadingSave, setIsLoadingSave] = useState(false);

  const { user } = useUserContext();

  const {
    data: questions = { data: [] },
    isLoading,
    isError,
  } = useQuery(getQuestionsQueryAdmin());

  const createMutation = useMutation({
    mutationFn: createQuestion,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: QUESTIONS_QUERY_KEYS });
      toast("Question saved:)", {
        type: "success",
      });
    },
    onError: () => {
      toast("Failed to save question:(", {
        type: "error",
      });
    },
  });

  const updateMutation = useMutation({
    mutationFn: (updatedQuestion: Question) => {
      return updateQuestion(updatedQuestion.id as string, updatedQuestion);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: QUESTIONS_QUERY_KEYS });
      toast("Question updated:)", {
        type: "success",
      });
    },
    onError: () => {
      toast("Failed to update the question:(", {
        type: "error",
      });
    },
  });

  const deleteMutation = useMutation({
    mutationFn: (id: string) => deleteQuestion(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: QUESTIONS_QUERY_KEYS });
      toast("Question deleted:)", {
        type: "success",
      });
    },
    onError: () => {
      toast("Failed to delete the question:(", {
        type: "error",
      });
    },
  });

  const handleSave = async (question: Question) => {
    setIsLoadingSave(true);
    if (editingQuestion) {
      await updateMutation.mutateAsync(question);
    } else {
      await createMutation.mutateAsync(question);
    }
    setIsEditing(false);
    setEditingQuestion(null);
    setIsLoadingSave(false);
  };

  const handleEdit = (question: Question) => {
    setEditingQuestion(question);
    setIsEditing(true);
  };

  const handleDelete = async (question: Question) => {
    if (question.id) {
      await deleteMutation.mutateAsync(question.id);
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
    setEditingQuestion(null);
  };

  const saveAllQuestions = async () => {
    await resaveQuestions();
    toast("Resaved all questions:)", {
      type: "success",
    });
  };

  if (isLoading || isLoadingSave)
    return (
      <div className="flex justify-center mt-8">
        <SyncLoader
          color={"#6456FF"}
          loading={isLoading}
          cssOverride={{
            display: "block",
            margin: "0 auto",
          }}
          size={30}
        />
      </div>
    );

  if (!user?.isAdmin) {
    return <div className="text-lg m-8">Unauthorized</div>;
  }

  if (isError)
    return <div className="text-lg m-8">Error loading questions.</div>;

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-4">Questions Manager</h1>

      {isEditing ? (
        <QuestionForm
          question={editingQuestion}
          onSave={handleSave}
          onCancel={handleCancel}
        />
      ) : (
        <div className="flex justify-between items-center mb-4">
          <div className="flex gap-4">
            <Button
              onClick={() => setIsEditing(true)}
              className="px-4 py-2 text-white rounded"
            >
              Add Question
            </Button>
            <Button
              onClick={saveAllQuestions}
              className="px-4 py-2 text-white rounded"
            >
              Resave all questions
            </Button>
          </div>
          <Button
            onClick={() => navigate("/admin/users")}
            className="px-4 py-2 text-white rounded"
          >
            Manage Users
          </Button>
        </div>
      )}

      <div className="space-y-4">
        {!isEditing &&
          questions.data
            .sort((a: Question, b: Question) => {
              return a.position - b.position;
            })
            .map((q: Question) => (
              <div
                key={q.id}
                className="py-3 px-4 bg-gray-100 rounded-md shadow-md flex flex-row items-center justify-between"
              >
                <div className="flex justify-start gap-3">
                  <div className="text-md">{q.position}</div>
                  <div className="text-md font-bold">{q.title}</div>
                  <div className="text-xs pt-1">{q.id}</div>
                  <DifficultyLevel level={q.difficulty} />
                </div>
                <div className="space-x-2 ml-8 flex justify-end">
                  <Button onClick={() => handleEdit(q)}>Edit</Button>
                  <Button onClick={() => handleDelete(q)} variant="secondary">
                    Delete
                  </Button>
                </div>
              </div>
            ))}
      </div>
    </div>
  );
};

export default QuestionManager;
