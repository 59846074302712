import React from "react";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-pgsql";
import "ace-builds/src-noconflict/theme-terminal";
import { FiCopy } from "react-icons/fi";
import { useMixpanel } from "react-mixpanel-browser";
import { useUserContext } from "../authentication/userContext";

export const SolutionView: React.FC<{
  solution?: string;
  questionId?: string;
}> = ({ solution, questionId }) => {
  const mixpanel = useMixpanel();
  const { user } = useUserContext();

  const handleCopy = () => {
    if (solution) {
      mixpanel?.track("copy_answer", {
        email: user?.email,
        name: user?.fullName,
        questionId,
      });
      navigator.clipboard.writeText(solution);
      alert("Copied to clipboard!");
    }
  };

  return (
    <div className="relative p-2 rounded-lg break-words whitespace-pre-wrap overflow-auto">
      <AceEditor
        mode="pgsql"
        theme="terminal"
        name="solution"
        fontSize={14}
        lineHeight={24}
        value={solution}
        showGutter={false}
        style={{
          width: "100%",
          maxHeight: "280px",
          borderRadius: "6px",
        }}
        setOptions={{
          readOnly: true,
          highlightActiveLine: false,
          highlightGutterLine: false,
        }}
        wrapEnabled={true}
      />
      <button
        className="absolute top-3 right-3 bg-white border border-gray-300 py-1.5 px-2 rounded shadow hover:bg-gray-100 flex items-center"
        onClick={handleCopy}
      >
        <FiCopy />
      </button>
    </div>
  );
};

export default SolutionView;
