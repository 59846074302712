import { Form, Link, useActionData, useNavigate } from "react-router-dom";
import { AuthenticationLayout } from "../layout";
import { Input } from "../../uiKit/input";
import { InputPassword } from "../../uiKit/passwordInput";
import { FaArrowLeft } from "react-icons/fa";
import Button from "../../uiKit/button";

const SignUpView = () => {
  const navigate = useNavigate();
  const actionData = useActionData() as Record<
    string,
    { _errors?: string[]; success?: string }
  >;

  if (actionData?.signupSuccess?.success) {
    return (
      <AuthenticationLayout
        title="Check your email"
        subTitle={actionData.signupSuccess.success}
      />
    );
  }

  return (
    <AuthenticationLayout
      title="Sign up to Data Noobs"
      subTitle="Create an account to unlock more questions"
    >
      <Form method="post" replace className="flex flex-col gap-4 mx-2">
        <Input
          isError={!!actionData?.fullName?._errors?.length}
          helpText={actionData?.fullName?._errors?.[0]}
          type="text"
          name="fullName"
          label="Full Name"
          inputMode="text"
        />
        <Input
          isError={!!actionData?.email?._errors?.length}
          helpText={actionData?.email?._errors?.[0]}
          type="email"
          name="email"
          label="Email"
          inputMode="email"
        />

        <div className="flex flex-col relative">
          <InputPassword
            isError={!!actionData?.password?._errors?.length}
            helpText={actionData?.password?._errors?.[0]}
            name="password"
            label="Password"
          />
        </div>

        <InputPassword
          isError={!!actionData?.["password-confirmation"]?._errors?.length}
          helpText={actionData?.["password-confirmation"]?._errors?.[0]}
          name="password-confirmation"
          label="Confirm password"
        />

        {actionData?.signupError?._errors && (
          <p className="text-red-500 text-sm">
            {actionData.signupError._errors[0]}
          </p>
        )}

        <div className="flex items-center my-2">
          <input type="checkbox" id="terms" className="mr-2" name="terms" />
          <label htmlFor="acceptTerms" className="text-sm text-gray-700">
            I accept the{" "}
            <Link
              to="/terms-and-conditions"
              className="text-blue-500 hover:underline"
            >
              Terms and Conditions
            </Link>{" "}
            and the{" "}
            <Link
              to="/privacy-policy"
              className="text-blue-500 hover:underline"
            >
              Privacy Policy
            </Link>
            .
          </label>
        </div>

        {actionData?.terms?._errors && (
          <p className="text-red-500 text-sm">{actionData.terms._errors[0]}</p>
        )}

        <Button type="submit" className="w-full my-1 mt-4">
          Sign Up
        </Button>

        <div className="text-sm text-dnPurple">
          <Link to="/login">Already have an account? Sign In</Link>
        </div>
        <button
          className=" text-sm flex flex-row items-center justify-center"
          onClick={() => navigate("/")}
        >
          <FaArrowLeft className="mr-2" /> Back to questions
        </button>
      </Form>
    </AuthenticationLayout>
  );
};

export default SignUpView;
