export const sqlCompletions = [
  // SQL Commands
  { caption: "SELECT", value: "SELECT", meta: "keyword" },
  { caption: "FROM", value: "FROM", meta: "keyword" },
  { caption: "WHERE", value: "WHERE", meta: "keyword" },
  { caption: "JOIN", value: "JOIN", meta: "keyword" },
  { caption: "LEFT JOIN", value: "LEFT JOIN", meta: "keyword" },
  { caption: "RIGHT JOIN", value: "RIGHT JOIN", meta: "keyword" },
  { caption: "INNER JOIN", value: "INNER JOIN", meta: "keyword" },
  { caption: "GROUP BY", value: "GROUP BY", meta: "keyword" },
  { caption: "ORDER BY", value: "ORDER BY", meta: "keyword" },
  { caption: "HAVING", value: "HAVING", meta: "keyword" },
  { caption: "LIMIT", value: "LIMIT", meta: "keyword" },
  { caption: "OFFSET", value: "OFFSET", meta: "keyword" },
  { caption: "CASE", value: "CASE", meta: "keyword" },
  { caption: "WHEN", value: "WHEN", meta: "keyword" },
  { caption: "THEN", value: "THEN", meta: "keyword" },
  { caption: "ELSE", value: "ELSE", meta: "keyword" },
  { caption: "END", value: "END", meta: "keyword" },
  { caption: "COUNT", value: "COUNT", meta: "function" },
  { caption: "SUM", value: "SUM", meta: "function" },
  { caption: "AVG", value: "AVG", meta: "function" },
  { caption: "MIN", value: "MIN", meta: "function" },
  { caption: "MAX", value: "MAX", meta: "function" },

  // Table names
  { caption: "orders", value: "orders", meta: "table" },
  { caption: "products", value: "products", meta: "table" },
  { caption: "products_to_orders", value: "products_to_orders", meta: "table" },
  { caption: "customers", value: "customers", meta: "table" },
  { caption: "sales_leads", value: "sales_leads", meta: "table" },

  // Columns for 'orders'
  { caption: "id", value: "id", meta: "orders" },
  { caption: "order_date", value: "order_date", meta: "orders" },
  { caption: "payment_method", value: "payment_method", meta: "orders" },
  { caption: "status", value: "status", meta: "orders" },
  { caption: "customer_id", value: "customer_id", meta: "orders" },

  // Columns for 'products'
  { caption: "title", value: "title", meta: "products" },
  { caption: "author", value: "author", meta: "products" },
  { caption: "price", value: "price", meta: "products" },
  { caption: "category", value: "category", meta: "products" },
  { caption: "release_date", value: "release_date", meta: "products" },

  // Columns for 'products_to_orders'
  { caption: "quantity", value: "quantity", meta: "products_to_orders" },
  { caption: "order_id", value: "order_id", meta: "products_to_orders" },
  {
    caption: "product_id",
    value: "product_id",
    meta: "products_to_orders",
  },

  // Columns for 'customers'
  { caption: "first_name", value: "first_name", meta: "customers" },
  { caption: "last_name", value: "last_name", meta: "customers" },
  { caption: "email", value: "email", meta: "customers" },
  { caption: "phone_number", value: "phone_number", meta: "customers" },
  { caption: "city", value: "city", meta: "customers" },
  { caption: "street", value: "street", meta: "customers" },
  { caption: "address_line", value: "address_line", meta: "customers" },

  // Columns for 'sales_leads'
  { caption: "lead_score", value: "lead_score", meta: "sales_leads" },
  { caption: "status", value: "status", meta: "sales_leads" },
  { caption: "source", value: "source", meta: "sales_leads" },
  { caption: "created_at", value: "created_at", meta: "sales_leads" },
];
