import { useNavigate } from "react-router-dom";
import { useUserContext } from "../authentication/userContext";
import LoginButton from "./LoginButton";
import Button from "../uiKit/button";

const Header: React.FC<{
  showLoginButton?: boolean;
  showGoPremium?: boolean;
}> = ({ showLoginButton = true, showGoPremium = true }) => {
  const { user } = useUserContext();
  const navigate = useNavigate();

  return (
    <div className="flex flex-row justify-between items-center bg-dnBlue w-full h-auto px-4">
      <div className="flex items-center">
        {showLoginButton && <LoginButton />}
        {showGoPremium && !user?.isPremium && (
          <Button
            onClick={() => {
              navigate("/premium");
            }}
            className="py-0.5 sm:py-2"
          >
            Go Premium
          </Button>
        )}
      </div>
      <div className="h-full object-cover w-[200px]">
        <div
          onClick={() => navigate("/")}
          className="cursor-pointer"
          role="link"
          tabIndex={0}
        >
          <img
            src="/logo_white.png"
            alt="Data Noobs"
            className="w-full h-auto object-contain"
          />
        </div>
      </div>
    </div>
  );
};

export default Header;
