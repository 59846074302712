import { MixpanelContext } from "react-mixpanel-browser";
import { apiClient } from "./apiClient"; // Assuming this is already created

export const QUESTIONS_QUERY_KEYS = ["questions"];
export const QUESTIONS_QUERY_KEYS_ADMIN = ["questions_admin"];

export const createQuestion = async (payload: {
  title: string;
  description: string;
  hint?: string;
  solution: string;
  difficulty: number;
  tags: string[];
  ignoreOrder: boolean;
  isVisible: boolean;
  position: number;
}) => {
  return await apiClient({
    path: "/questions",
    method: "post",
    body: payload,
  });
};

export const updateQuestion = async (
  id: string,
  payload: {
    title: string;
    description: string;
    hint?: string;
    solution: string;
    difficulty: number;
    tags: string[];
    ignoreOrder: boolean;
    isVisible: boolean;
    position?: number;
  }
) => {
  return await apiClient({
    path: `/questions/${id}`,
    method: "PUT",
    body: payload,
  });
};

export const deleteQuestion = async (id: string) => {
  return await apiClient({
    path: `/questions/${id}`,
    method: "DELETE",
  });
};

export const resaveQuestions = async () => {
  return await apiClient({
    path: "/resave-questions",
    method: "POST",
  });
};

export const getQuestions = async () => {
  return await apiClient({
    path: "/questions",
  });
};

export const getQuestionsQuery = (mixpanel?: MixpanelContext) => ({
  queryKey: QUESTIONS_QUERY_KEYS,
  queryFn: getQuestions,
  onError: (error: Error) => {
    mixpanel?.track("error", {
      type: "client_get_questions_error",
      error: error?.message || "Unknown error",
    });
    console.error("Error in questions query:", error);
  },
});

export const getQuestionsAdmin = async () => {
  return await apiClient({
    path: "/questions-admin",
  });
};

export const getQuestionsQueryAdmin = () => ({
  queryKey: QUESTIONS_QUERY_KEYS_ADMIN,
  queryFn: getQuestionsAdmin,
});
