import {
  useActionData,
  useNavigation,
  Form,
  Link,
  useNavigate,
  useLocation,
} from "react-router-dom";

import { AuthenticationLayout } from "../layout";
import { Input } from "../../uiKit/input";
import { InputPassword } from "../../uiKit/passwordInput";
import { FaArrowLeft } from "react-icons/fa";
import Button from "../../uiKit/button";

export const LoginForm = () => {
  const navigation = useNavigation();
  const isLoggingIn = navigation.formData?.get("email") != null;
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const from = params.get("from") || "/";

  const actionData = useActionData() as Record<
    string,
    { _errors?: string[]; success?: string }
  >;

  return (
    <AuthenticationLayout
      title="Welcome to Data Noobs SQL platform"
      subTitle={<>Log in</>}
    >
      <Form method="post" replace className="flex flex-col gap-4 mx-2">
        <input type="hidden" name="redirectTo" value={from} />

        <Input
          isError={!!actionData?.email?._errors?.length}
          helpText={actionData?.email?._errors?.[0]}
          type="text"
          inputMode="email"
          name="email"
          label="Email"
        />

        <InputPassword
          isError={!!actionData?.password?._errors?.length}
          helpText={actionData?.password?._errors?.[0]}
          name="password"
          label="Password"
        />

        <div className="text-right">
          <Link className="w-fit text-xs" to="/forgot-password">
            Forgot password?
          </Link>
        </div>

        {actionData?.actionError?._errors ? (
          <p className="text-red-500 text-sm">
            {actionData?.actionError?._errors?.[0]}
          </p>
        ) : null}

        <Button
          type="submit"
          className={`w-full my-1 ${isLoggingIn ? "cursor-pointer" : "cursor-default"}`}
        >
          Sign In
        </Button>
        <div className="text-sm text-dnPurple">
          <Link to="/signup">Don't have an account yet? Sign up</Link>
        </div>
        <button
          className=" text-sm flex flex-row items-center justify-center"
          onClick={() => navigate("/")}
        >
          <FaArrowLeft className="mr-2" /> Back to questions
        </button>
      </Form>
    </AuthenticationLayout>
  );
};
