import { QueryClient } from "@tanstack/react-query";
import { loginSchema } from "../schemaValidators";
import { LoaderFunctionArgs, redirect } from "react-router-dom";

import { USER_QUERY_KEYS, getProfileQuery, login } from "../../api/loginApi";
import { isUserPremium } from "../userContext";

export const loginAction =
  (queryClient: QueryClient) =>
  async ({ request }: LoaderFunctionArgs) => {
    const formData = await request.formData();
    const email = formData.get("email") as string;
    const password = formData.get("password") as string;

    try {
      const data = {
        email,
        password,
      };

      const result = loginSchema.safeParse(data);

      if (!result.success) {
        return result?.error?.format();
      }

      const response = await login({ email, password });
      if (!response.success) {
        return {
          actionError: {
            _errors: [
              "Invalid login attempt, if you've signed up, please check your email and password or make sure you've verified your email address.",
            ],
          },
        };
      }

      queryClient.setQueryData(USER_QUERY_KEYS, response);
    } catch (error) {
      return {
        actionError: {
          _errors: [
            "Invalid login attempt, if you've signed up, please check your email and password or make sure you've verified your email address.",
          ],
        },
      };
    }

    const userData = await queryClient.fetchQuery(getProfileQuery());
    if (userData?.success && !isUserPremium(userData.data)) {
      return redirect("/premium");
    }

    const redirectTo = formData.get("from") as string | null;
    return redirect(redirectTo || "/");
  };
