import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { fetchUsers, updateUser } from "../api/usersApi";
import { useUserContext } from "../authentication/userContext";
import { DBUser } from "./types";
import { toast } from "react-toastify";
import { SyncLoader } from "react-spinners";

const TableContainer = styled.div`
  padding: 20px;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  overflow-x: auto;
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  min-width: 800px;
`;

const StyledTh = styled.th<{
  isSortable?: boolean;
  isActive?: boolean;
  sortDirection?: "asc" | "desc";
}>`
  background-color: ${({ isActive }) => (isActive ? "#e0e0e0" : "#f4f4f4")};
  color: #333;
  padding: 10px;
  border-bottom: 2px solid #ddd;
  text-align: left;
  cursor: ${({ isSortable }) => (isSortable ? "pointer" : "default")};
  transition: background-color 0.3s;
  position: relative;

  &:hover {
    background-color: ${({ isSortable }) =>
      isSortable ? "#d0d0d0" : "#f4f4f4"};
  }

  &:after {
    content: ${({ isActive }) => (isActive ? "'▼'" : "''")};
    font-size: 0.8em;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%)
      ${({ isActive, sortDirection }) =>
        isActive && sortDirection === "asc"
          ? "rotate(180deg)"
          : "rotate(0deg)"};
    color: #666;
  }
`;

const StyledTd = styled.td<{ hasUnsavedChanges?: boolean }>`
  padding: 10px;
  border-bottom: 1px solid #ddd;
  text-align: left;
  background-color: ${({ hasUnsavedChanges }) =>
    hasUnsavedChanges ? "#fff3cd" : "transparent"};
`;

const StyledInput = styled.input`
  padding: 5px;
  margin: 0;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const StyledButton = styled.button<{ disabled?: boolean }>`
  padding: 5px 10px;
  background-color: ${({ disabled }) => (disabled ? "#ccc" : "#007bff")};
  color: white;
  border: none;
  border-radius: 4px;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  &:hover {
    background-color: ${({ disabled }) => (disabled ? "#ccc" : "#0056b3")};
  }
`;

export const UsersTable: React.FC = () => {
  const { user } = useUserContext();
  const [users, setUsers] = useState<DBUser[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<DBUser[]>([]);
  const [changes, setChanges] = useState<Record<string, Partial<DBUser>>>({});
  const [originalUsers, setOriginalUsers] = useState<Record<string, DBUser>>(
    {}
  );
  const [sortField, setSortField] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");
  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState<string>("");

  useEffect(() => {
    const loadUsers = async () => {
      const response = await fetchUsers();
      if (response.success) {
        setUsers(response.data);
        setFilteredUsers(response.data);
        const originalData = response.data.reduce(
          (acc, user) => {
            acc[user.id] = user;
            return acc;
          },
          {} as Record<string, DBUser>
        );
        setOriginalUsers(originalData);
      }
      setIsLoading(false);
    };
    loadUsers();
  }, []);

  useEffect(() => {
    const filtered = users.filter((user) =>
      user.email.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredUsers(filtered);
  }, [searchQuery, users]);

  const handleSort = (field: string) => {
    const isAsc = sortField === field && sortDirection === "asc";
    setSortDirection(isAsc ? "desc" : "asc");
    setSortField(field);

    const sortedUsers = [...filteredUsers].sort((a, b) => {
      const aValue = a[field as keyof DBUser] ?? "";
      const bValue = b[field as keyof DBUser] ?? "";
      if (aValue < bValue) return isAsc ? 1 : -1;
      if (aValue > bValue) return isAsc ? -1 : 1;
      return 0;
    });

    setFilteredUsers(sortedUsers);
  };

  const handleFieldChange = (id: string, field: keyof DBUser, value: any) => {
    setChanges((prevChanges) => ({
      ...prevChanges,
      [id]: {
        ...prevChanges[id],
        [field]: value,
      },
    }));

    setUsers((prevUsers) =>
      prevUsers.map((user) =>
        user.id === id ? { ...user, [field]: value } : user
      )
    );
  };

  const handleUpdateUser = async (id: string) => {
    const userChanges = changes[id];
    if (!userChanges) return;

    const response = await updateUser(id, {
      premiumExpiry: userChanges.premiumExpiry
        ? userChanges.premiumExpiry.toISOString().split("T")[0]
        : undefined,
      aiRequestsCount: userChanges.aiRequestsCount,
    });

    if (response.success) {
      setChanges((prevChanges) => {
        const { [id]: _, ...rest } = prevChanges;
        return rest;
      });
      toast("User updated successfully :)", { type: "success" });
    } else {
      toast("Failed to update user :(", { type: "error" });
    }
  };

  const handleRevertChanges = (id: string) => {
    const originalUser = originalUsers[id];
    if (originalUser) {
      setUsers((prevUsers) =>
        prevUsers.map((user) => (user.id === id ? originalUser : user))
      );
      setChanges((prevChanges) => {
        const { [id]: _, ...rest } = prevChanges;
        return rest;
      });
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center mt-8">
        <SyncLoader color={"#6456FF"} loading={isLoading} size={30} />
      </div>
    );
  }

  if (!user?.isAdmin) {
    return <div>Access Denied</div>;
  }

  return (
    <TableContainer>
      <h1 className="text-2xl font-bold text-gray-800 mb-6 mt-4 mx-2">
        Users Management
      </h1>
      <div className="mb-4">
        <StyledInput
          type="text"
          placeholder="Search by email"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>
      <StyledTable>
        <thead>
          <tr>
            <StyledTh>ID</StyledTh>
            <StyledTh
              isSortable
              isActive={sortField === "email"}
              sortDirection={sortDirection}
              onClick={() => handleSort("email")}
            >
              Email
            </StyledTh>
            <StyledTh
              isSortable
              isActive={sortField === "fullName"}
              sortDirection={sortDirection}
              onClick={() => handleSort("fullName")}
            >
              Full Name
            </StyledTh>
            <StyledTh>Is Email Confirmed</StyledTh>
            <StyledTh>External Payment ID</StyledTh>
            <StyledTh
              isSortable
              isActive={sortField === "lastPayedAt"}
              sortDirection={sortDirection}
              onClick={() => handleSort("lastPayedAt")}
            >
              Last Payed At
            </StyledTh>
            <StyledTh>AI Requests Restart At</StyledTh>
            <StyledTh>Is Admin</StyledTh>
            <StyledTh
              isSortable
              isActive={sortField === "createdAt"}
              sortDirection={sortDirection}
              onClick={() => handleSort("createdAt")}
            >
              Created At
            </StyledTh>
            <StyledTh
              isSortable
              isActive={sortField === "premiumExpiry"}
              sortDirection={sortDirection}
              onClick={() => handleSort("premiumExpiry")}
            >
              Premium Expiry
            </StyledTh>
            <StyledTh>AI Requests Count</StyledTh>
            <StyledTh>Actions</StyledTh>
          </tr>
        </thead>
        <tbody>
          {filteredUsers.map((user) => (
            <tr key={user.id}>
              <StyledTd hasUnsavedChanges={!!changes[user.id]}>
                {user.id}
              </StyledTd>
              <StyledTd hasUnsavedChanges={!!changes[user.id]}>
                {user.email}
              </StyledTd>
              <StyledTd hasUnsavedChanges={!!changes[user.id]}>
                {user.fullName}
              </StyledTd>
              <StyledTd hasUnsavedChanges={!!changes[user.id]}>
                {user.isEmailConfirmed ? "Yes" : "No"}
              </StyledTd>
              <StyledTd hasUnsavedChanges={!!changes[user.id]}>
                {user.externalPaymentId}
              </StyledTd>
              <StyledTd hasUnsavedChanges={!!changes[user.id]}>
                {user.lastPayedAt?.toISOString()}
              </StyledTd>
              <StyledTd hasUnsavedChanges={!!changes[user.id]}>
                {user.aiRequestsRestartAt?.toISOString()}
              </StyledTd>
              <StyledTd hasUnsavedChanges={!!changes[user.id]}>
                {user.isAdmin ? "Yes" : "No"}
              </StyledTd>
              <StyledTd hasUnsavedChanges={!!changes[user.id]}>
                {user.createdAt.toISOString()}
              </StyledTd>
              <StyledTd hasUnsavedChanges={!!changes[user.id]}>
                <StyledInput
                  type="date"
                  value={
                    user.premiumExpiry
                      ? user.premiumExpiry.toISOString().split("T")[0]
                      : ""
                  }
                  onChange={(e) =>
                    handleFieldChange(
                      user.id,
                      "premiumExpiry",
                      e.target.value ? new Date(e.target.value) : undefined
                    )
                  }
                />
              </StyledTd>
              <StyledTd hasUnsavedChanges={!!changes[user.id]}>
                <StyledInput
                  type="number"
                  value={user.aiRequestsCount ?? ""}
                  onChange={(e) =>
                    handleFieldChange(
                      user.id,
                      "aiRequestsCount",
                      e.target.value ? Number(e.target.value) : undefined
                    )
                  }
                />
              </StyledTd>
              <StyledTd>
                <div className="flex items-center gap-2">
                  <StyledButton
                    onClick={() => handleUpdateUser(user.id)}
                    disabled={!changes[user.id]}
                    className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    Save
                  </StyledButton>
                  <StyledButton
                    onClick={() => handleRevertChanges(user.id)}
                    disabled={!changes[user.id]}
                    className="text-gray-600 hover:text-gray-800 disabled:opacity-50 disabled:cursor-not-allowed"
                    title="Revert changes"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </StyledButton>
                </div>
              </StyledTd>
            </tr>
          ))}
        </tbody>
      </StyledTable>
    </TableContainer>
  );
};
