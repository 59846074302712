import { useNavigate } from "react-router-dom";
import { useUserContext } from "../authentication/userContext";
import { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { USER_QUERY_KEYS } from "../api/loginApi";
import Button from "../uiKit/button";

const LoginButton = () => {
  const navigate = useNavigate();
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const queryClient = useQueryClient();
  const { user } = useUserContext();
  const handleLogout = () => {
    localStorage.removeItem("token");

    queryClient.removeQueries({ queryKey: USER_QUERY_KEYS });
    queryClient.invalidateQueries({ queryKey: USER_QUERY_KEYS });
    navigate("/");
  };

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const getInitials = (fullName?: string | null) => {
    if (!fullName) return;
    try {
      const names = fullName.split(" ");
      const initials = names.map((name) => name[0].toUpperCase()).join("");
      return initials.slice(0, 2);
    } catch (e) {
      return null;
    }
  };
  const initials = getInitials(user?.fullName);

  return (
    <div className="relative ml-8 mr-4">
      {user ? (
        <button
          className="flex items-center justify-center w-8 h-8 rounded-full bg-amber-600 text-white text-md font-semibold focus:outline-none"
          onClick={toggleDropdown}
        >
          {initials || user.fullName}
        </button>
      ) : (
        <Button
          className="h-fit mr-6 py-0 sm:py-2"
          onClick={() => navigate("/login")}
        >
          Sign In
        </Button>
      )}
      {dropdownVisible && (
        <div
          className={`absolute top-full left-0 mt-2 w-32 bg-white rounded-md shadow-lg shadow-black z-10 ${
            dropdownVisible ? "" : "hidden"
          }`}
        >
          <button
            onClick={handleLogout}
            className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100 hover:rounded-md"
          >
            Logout
          </button>
        </div>
      )}
    </div>
  );
};

export default LoginButton;
