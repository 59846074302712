import React, { useState } from "react";
import { getAiOpinion } from "../api/aiApi";
import { useUserContext } from "../authentication/userContext";
import Button from "../uiKit/button";
import ReactMarkdown from "react-markdown";
import { SyncLoader } from "react-spinners";
import { useMixpanel } from "react-mixpanel-browser";

interface AiOpinionProps {
  questionId: string;
  query: string;
  feedback: string | null;
  setFeedback: (feedback: string | null) => void;
}

const AiOpinion: React.FC<AiOpinionProps> = ({
  questionId,
  query,
  feedback,
  setFeedback,
}) => {
  const [loading, setLoading] = useState(false);

  const { user } = useUserContext();
  const mixpanel = useMixpanel();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setFeedback(null);

    try {
      const response = await getAiOpinion({
        questionId,
        query,
        userEmail: user?.email,
      });

      mixpanel?.track("ai_opinion", {
        email: user?.email,
        name: user?.fullName,
        questionId,
        query,
        response: response.data,
      });

      if (response.success) {
        setFeedback(response.data.opinion);
      } else {
        setFeedback(response.data);
      }
    } catch (error) {
      mixpanel?.track("ai_error", {
        email: user?.email,
        name: user?.fullName,
        questionId,
        query,
        error,
      });
      setFeedback("An error occurred while fetching feedback");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-center">
      {!loading && !feedback && (
        <h2 className="text-2xl font-bold mb-6 text-center">
          Get AI generated feedback for your current query
        </h2>
      )}
      <form onSubmit={handleSubmit}>
        <SyncLoader
          color={"#6456FF"}
          loading={loading}
          cssOverride={{
            display: "block",
            marginTop: 8,
          }}
          size={30}
        />
        {!loading && !feedback && (
          <Button
            type="submit"
            className="transition duration-200"
            disabled={loading || !query}
          >
            Generate
          </Button>
        )}
      </form>
      {feedback && (
        <div className="px-3 rounded-md whitespace-pre-wrap break-words">
          <ReactMarkdown>{feedback}</ReactMarkdown>
          <Button
            onClick={() => setFeedback(null)}
            className="my-4 transition duration-200"
          >
            Start Over
          </Button>
        </div>
      )}
    </div>
  );
};

export default AiOpinion;
