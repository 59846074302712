import { DBUser } from "../admin/types";
import { apiClient } from "./apiClient";

export const fetchUsers = async (): Promise<{
  data: DBUser[];
  success: boolean;
}> => {
  const response = await apiClient({
    path: "/users",
    method: "GET",
  });

  if (response.success) {
    // Convert date strings to Date objects
    response.data = response.data.map((user: any) => ({
      ...user,
      lastPayedAt: user.lastPayedAt ? new Date(user.lastPayedAt) : undefined,
      premiumExpiry: user.premiumExpiry
        ? new Date(user.premiumExpiry)
        : undefined,
      aiRequestsRestartAt: user.aiRequestsRestartAt
        ? new Date(user.aiRequestsRestartAt)
        : undefined,
      createdAt: new Date(user.createdAt),
      updatedAt: new Date(user.updatedAt),
    }));
  }

  return response;
};

export const updateUser = async (
  id: string,
  data: { premiumExpiry?: string | null; aiRequestsCount?: number | null }
) => {
  return await apiClient({
    path: `/users/${id}`,
    method: "PUT",
    body: data,
  });
};
