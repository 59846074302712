export interface QuestionData {
  id: string;
  title: string;
  description: string;
  correctAnswer?: Record<string, unknown>[];
  ignoreOrder: boolean;
  hint: string;
  solution: string;
  difficulty: number;
  tags: string[];
  position: number;
  isVisible: boolean;
  isFree: boolean;
  outputSampleHeaders: string[];
  outputSampleValues: string[];
}

export const TABS = {
  DESCRIPTION: "Description",
  HINT: "Hint",
  SOLUTION: "Solution",
  AI: "AI Feedback",
};
