import { MixpanelProvider } from "react-mixpanel-browser";
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import Header from "./components/Header";
import QuestionView from "./sqlEditor/questionView";
import QuestionTable from "./components/QuestionsTable";
import environment from "./config/environment";
import Footer from "./components/Footer";
import { EmailConfirmationForm } from "./authentication/emailConfirmation/component";
import SignUpView from "./authentication/signUp/component";
import { LoginForm } from "./authentication/login/component";
import { loginAction } from "./authentication/login/action";
import { signUpAction } from "./authentication/signUp/action";
import { emailConfirmationAction } from "./authentication/emailConfirmation/action";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { rootLoader } from "./components/rootLoader";
import { Layout } from "./components/RootLayout";
import { ForgotPasswordForm } from "./authentication/forgotPassword/component";
import { ResetPasswordForm } from "./authentication/resetPassword/component";
import { forgotPasswordAction } from "./authentication/forgotPassword/action";
import { resetPasswordAction } from "./authentication/resetPassword/action";
import PaymentPage from "./authentication/payment/PaymentPage";
import QuestionManager from "./admin/questionsManager";
import { ToastContainer } from "react-toastify";
import FeedbackTable from "./admin/FeedbackTable";
import { TermsAndConditions } from "./informationPages/TermsAndConditions";
import { PrivacyPolicy } from "./informationPages/PrivacyPolicy";
import CookieConsent from "react-cookie-consent";
// import Cookies from "universal-cookie";
import PremiumOptions from "./authentication/payment/PremiumOptions";
import { UsersTable } from "./admin/UsersTable";

const MIXPANEL_TOKEN =
  environment.env === "local" ? undefined : "d982a31414e729dd96995133b7692dcc";
const MIXPANEL_CONFIG = {
  track_pageview: true,
  persistence: "localStorage",
};

export const QUESTION_ID_PATH_PARAM = "questionId";
export const PAYMENT_MONTHS = "paymentMonth";

// const cookies = new Cookies();
const queryClient = new QueryClient();

const router = createBrowserRouter([
  {
    id: "root",
    path: "/",
    loader: rootLoader(queryClient),
    element: <Layout />,
    children: [
      { index: true, element: <Navigate to="/questions" replace /> },
      {
        path: "/questions",
        element: (
          <div className="w-full">
            <Header />
            <QuestionTable />
            <Footer />
          </div>
        ),
      },
      {
        path: `/question/:${QUESTION_ID_PATH_PARAM}`,
        element: (
          <div className="w-full">
            <Header />
            <QuestionView />
            <Footer />
          </div>
        ),
      },
      {
        path: `/payment-paypal/:${PAYMENT_MONTHS}`,
        loader: rootLoader(queryClient),
        element: (
          <div>
            <Header showGoPremium={false} />
            <PaymentPage />
            <Footer />
          </div>
        ),
      },
      {
        path: "/premium",
        loader: rootLoader(queryClient),
        element: (
          <div>
            <Header showGoPremium={false} />
            <PremiumOptions />
            <Footer />
          </div>
        ),
      },
    ],
  },
  {
    path: "/login",
    element: (
      <div className="w-full">
        <Header showLoginButton={false} showGoPremium={false} />
        <LoginForm />
        <Footer />
      </div>
    ),
    action: loginAction(queryClient),
  },
  {
    path: "/signup",
    element: (
      <div className="w-full">
        <Header showLoginButton={false} showGoPremium={false} />
        <SignUpView />
        <Footer />
      </div>
    ),
    action: signUpAction(),
  },
  {
    path: "/email-confirmation",
    element: (
      <div className="w-full">
        <Header showLoginButton={false} />
        <EmailConfirmationForm />
        <Footer />
      </div>
    ),
    action: emailConfirmationAction(),
  },
  {
    path: "/forgot-password",
    element: (
      <div className="w-full">
        <Header showLoginButton={false} />
        <ForgotPasswordForm />
        <Footer />
      </div>
    ),
    action: forgotPasswordAction(),
  },
  {
    path: "/reset-password",
    element: (
      <div className="w-full">
        <Header showLoginButton={false} />
        <ResetPasswordForm />
        <Footer />
      </div>
    ),
    action: resetPasswordAction(),
  },
  {
    path: "/admin",
    element: (
      <div className="w-full">
        <Header />
        <QuestionManager />
        <Footer />
      </div>
    ),
  },
  {
    path: "/admin/feedback",
    element: (
      <div className="w-full">
        <Header />
        <FeedbackTable />
        <Footer />
      </div>
    ),
  },
  {
    path: "/terms-and-conditions",
    element: (
      <div className="w-full">
        <Header />
        <TermsAndConditions />
        <Footer />
      </div>
    ),
  },
  {
    path: "/privacy-policy",
    element: (
      <div className="w-full">
        <Header />
        <PrivacyPolicy />
        <Footer />
      </div>
    ),
  },
  {
    path: "/admin/users",
    element: (
      <div className="w-full">
        <Header />
        <UsersTable />
        <Footer />
      </div>
    ),
  },
  {
    path: "*",
    element: <div>404 Not found</div>,
  },
]);

// const hasConsent = cookies.get("userConsent");
function DataNoobsApp() {
  return (
    <QueryClientProvider client={queryClient}>
      <MixpanelProvider config={MIXPANEL_CONFIG} token={MIXPANEL_TOKEN}>
        <div className="flex flex-col min-h-screen">
          <div className="flex-grow">
            <ToastContainer
              autoClose={8000}
              limit={1}
              pauseOnFocusLoss={false}
            />
            <RouterProvider router={router} />
          </div>
          <CookieConsent
            location="bottom"
            buttonText="Accept"
            declineButtonText="Decline"
            enableDeclineButton
            cookieName="userConsent"
            style={{
              background: "#6456FF",
              color: "#ffffff",
            }}
            buttonStyle={{
              color: "#ffffff",
              background: "#4CAF50",
              fontSize: "15px",
            }}
            declineButtonStyle={{
              color: "#ffffff",
              background: "#f44336",
              fontSize: "15px",
            }}
            expires={365}
            acceptOnOverlayClick={true}
            overlay
            visible="hidden"
          >
            This website uses cookies to enhance the user experience.{" "}
            <a href="/privacy-policy" className="text-gray-300 hover:underline">
              Privacy Policy
            </a>
          </CookieConsent>
        </div>
      </MixpanelProvider>
    </QueryClientProvider>
  );
}

export default DataNoobsApp;
