import { User } from "../authentication/userContext";
import { apiClient } from "./apiClient";

export const USER_QUERY_KEYS = ["user"];

export const login = async (payload: { email: string; password: string }) => {
  const response = await apiClient({
    path: "/login",
    method: "post",
    body: payload,
  });
  if (response.success) {
    localStorage.setItem("token", response.data.token);
  }
  return response;
};

export const signup = async (payload: {
  email: string;
  password: string;
  fullName: string;
}) => {
  return await apiClient({
    path: "/signup",
    method: "post",
    body: payload,
  });
};

export const forgotPassword = async (payload: { email: string }) => {
  return await apiClient({
    path: "/forgot-password",
    method: "post",
    body: payload,
  });
};

export const resetPassword = async (payload: {
  password: string;
  requestId: string;
}) => {
  return await apiClient({
    path: "/reset-password",
    method: "post",
    body: payload,
  });
};

export const profile = async (): Promise<{ data: User; success: boolean }> => {
  return await apiClient({
    path: "/profile",
  });
};

export const getProfileQuery = () => ({
  queryKey: USER_QUERY_KEYS,
  queryFn: profile,
});

export const emailConfirmation = (payload: { email: string }) =>
  apiClient({
    path: "/email-confirmation",
    method: "POST",
    body: payload,
  });
