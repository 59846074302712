import { QueryClient, useQuery } from "@tanstack/react-query";
import { USER_QUERY_KEYS, getProfileQuery } from "../api/loginApi";

export interface User {
  email: string;
  fullName: string | null;
  id: string;
  premiumExpiry?: Date;
  isPremium?: boolean;
  isAdmin?: boolean;
}

interface UserContextType {
  user: User | null;
  isLoggedIn: boolean;
}

export const getUserData = async (
  queryClient: QueryClient
): Promise<{ data: User | null; success: boolean } | null> => {
  const cachedUser =
    queryClient.getQueryData<
      { data: User | null; success: boolean } | undefined
    >(USER_QUERY_KEYS) ?? null;

  if (cachedUser) {
    return cachedUser;
  }

  try {
    const data = await queryClient.fetchQuery(getProfileQuery());
    return data;
  } catch (e) {
    console.error("Failed to get user data", e);
    return null;
  }
};

export const isUserPremium = (user?: User | null) =>
  !!user?.premiumExpiry && new Date(user.premiumExpiry).getTime() > Date.now();

export const useUserContext = (): UserContextType => {
  const { data } = useQuery(getProfileQuery());
  const user = data?.data;
  const userWithPremiumBool = {
    ...user,
    isPremium: isUserPremium(user),
  } as User;

  return {
    user: user ? userWithPremiumBool : null,
    isLoggedIn: !!user,
  };
};
