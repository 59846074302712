import { PropsWithChildren, ReactNode } from "react";
import { redirect } from "react-router-dom";
import { useUserContext } from "./userContext";

export const AuthenticationLayout = ({
  children,
  title,
  subTitle,
  illustration,
}: PropsWithChildren & {
  title?: ReactNode;
  subTitle?: ReactNode;
  illustration?: ReactNode;
}) => {
  const { user } = useUserContext();

  if (user) {
    redirect("/");
  }

  return (
    <div className="flex flex-col justify-start mt-16 min-h-screen bg-white pb-4 pt-4 px-3">
      <div className="w-full max-w-[448px] min-w-[80%] sm:min-w-[448px] mx-auto text-center text-dnBlue">
        {title && (
          <h1
            className={`text-4xl font-semibold leading-10 ${!subTitle ? "mb-16" : "mb-4"}`}
          >
            {title}
          </h1>
        )}
        {illustration && <div className="inline-block m-8">{illustration}</div>}
        {subTitle && <p className="mb-8 text-lg">{subTitle}</p>}
        {children}
      </div>
    </div>
  );
};
