import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";

export const PayPalButtonsWrapper = ({
  createOrder,
  handleApprove,
  setError,
  setIsLoading,
}: {
  createOrder: (
    data: any,
    actions: {
      order: {
        create: (arg: any) => any;
      };
    },
  ) => Promise<any>;
  handleApprove: (orderId: string) => Promise<void>;
  setError: (value: string) => void;
  setIsLoading: (value: boolean) => void;
}) => {
  const [{ isPending, isResolved, isRejected }] = usePayPalScriptReducer();

  if (isPending) {
    setIsLoading(true);
  }

  if (isRejected) {
    setIsLoading(false);
    setError(
      "Failed to load PayPal payment. Please try again or contact support at admin@data-noobs.com.",
    );
  }

  if (isResolved) {
    return (
      <PayPalButtons
        createOrder={createOrder}
        onApprove={async (data, actions) => {
          if (actions.order) {
            await actions.order.capture();
            await handleApprove(data.orderID);
          }
        }}
        onError={(err: Record<string, unknown>) => {
          setError(
            "Payment failed. Please try again or contact support at admin@data-noobs.com",
          );
          setIsLoading(false);
        }}
        onInit={() => {
          setIsLoading(false);
        }}
        style={{
          layout: "vertical",
          color: "blue",
          shape: "pill",
          label: "pay",
        }}
      />
    );
  }

  return null;
};
