import { Link } from "react-router-dom";
// import { resetCookieConsentValue } from "react-cookie-consent";
// import Cookies from "universal-cookie";

const Footer: React.FC = () => {
  // const cookies = new Cookies();

  return (
    <footer className="bg-dnBlue text-white py-4">
      <div className="container mx-auto flex flex-col md:flex-row justify-between items-center px-2 space-y-4 md:space-y-0">
        <div className="text-sm text-center md:text-left">
          © 2024 Data Noobs. All rights reserved.
        </div>
        <div className="flex flex-col space-y-2 text-sm text-center md:text-left">
          <Link to="/terms-and-conditions" className="hover:underline">
            Terms and Conditions
          </Link>
          <Link to="/privacy-policy" className="hover:underline">
            Privacy Policy
          </Link>
          <a href="mailto:alon@data-noobs.com" className="hover:underline">
            Contact Us
          </a>
          {/* <button
            onClick={() => {
              resetCookieConsentValue();
              cookies.remove("userConsent");
              window.location.reload();
            }}
            className="hover:underline md:text-left"
          >
            Cookie Settings
          </button> */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
