import { useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

import { AuthenticationLayout } from "../layout";
import { emailConfirmation } from "../../api/loginApi";

export const EmailConfirmationForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const email = params.get("e");
  const expirationDate = params.get("d");

  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  useEffect(() => {
    const confirmEmail = async () => {
      if (!email || !expirationDate) {
        setErrorMessage(
          "Something went wrong, please contact support at admin@data-noobs.com",
        );
        setLoading(false);
        return;
      }

      if (Number(expirationDate) < new Date().getTime()) {
        setErrorMessage("This link has expired, please sign up again.");
        setLoading(false);
        return;
      }

      try {
        await emailConfirmation({ email: atob(email) });
        setSuccessMessage(
          "Account successfully confirmed, you can now login to your account and start practicing.",
        );
      } catch {
        setErrorMessage(
          "Invalid login attempt. If you've signed up, please check your email and password or make sure you've verified your email address.",
        );
      } finally {
        setLoading(false);
      }
    };

    confirmEmail();
  }, [email, expirationDate]);

  if (loading) {
    return (
      <AuthenticationLayout
        title="Email confirmation"
        subTitle="Confirming your account..."
      />
    );
  }

  if (errorMessage) {
    return (
      <AuthenticationLayout
        title="Email confirmation"
        subTitle={errorMessage}
      />
    );
  }

  if (successMessage) {
    return (
      <AuthenticationLayout title="Welcome!" subTitle={successMessage}>
        <button
          onClick={() => navigate("/login")}
          className="bg-dnPurple text-white px-4 py-2 rounded-lg focus:outline-none hover:bg-dnPurpleDark"
        >
          Sign In
        </button>
      </AuthenticationLayout>
    );
  }

  return null;
};
