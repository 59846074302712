import { QuestionData } from "../../sqlEditor/types";
import { User } from "../userContext";

export const isQuestionEnabled = (
  question: QuestionData,
  user: User | null,
) => {
  const isDisabled = !user?.isPremium && !question.isFree;
  return !isDisabled;
};
